<template>
  <v-container id="productCategories" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card>
      <v-card-title>
        {{ $t('admin.sub.productCategories.subtitle') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('admin.sub.productCategories.table.search')" single-line hide-details />
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="productCategories" :search="table.search" :loading="loading">
        <template #[`item.createdAt`]="{ item }">
          <span>{{ formatDate(item.createdAt) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';

  export default {
    name: 'ProductCategories',
    components: { AlertNotification },
    data () {
      return {
        alert: '',
        createDriverForm: false,
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('admin.sub.productCategories.table.column.position'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'position'
            },
            {
              text: this.$i18n.t('admin.sub.productCategories.table.column.name'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'name'
            },
            {
              text: this.$i18n.t('admin.sub.productCategories.table.column.description'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'description'
            },
            {
              text: this.$i18n.t('admin.sub.productCategories.table.column.createdAt'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'createdAt'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: ['position'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        productCategories: []
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      formatDate (date) {
        return DateTime.fromISO(date, { setZone: true }).toLocaleString({ locale: 'fr-fr' });
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'product/categories/');
          if (response.data !== undefined && response.data.categories !== undefined) {
            this.productCategories = response.data.categories;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      }
    }
  };
</script>
